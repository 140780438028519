<template>
  <div class="p-3">
    <b-form
      @submit.stop.prevent="onSubmit"
      @reset.prevent="resetForm"
      autocomplete="off"
    >
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Varsayılan Uye Grubu">
            <v-select
              v-model="form.var_uye_k_no"
              :options="uyeGruplari"
              :reduce="(uye) => uye.k_no"
              label="baslik"
              :clearable="false"
              class="invoice-filter-select d-block"
            >
              <template slot="selected-option" slot-scope="option">
                {{ option.baslik }}
              </template>
              <template slot="option" slot-scope="option">
                {{ option.baslik }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Üyelik Sözleşme Metni">
            <v-select
              v-model="form.var_uye_soz_k_no"
              :options="sayfalar"
              :reduce="(sayfa) => sayfa.k_no"
              label="baslik"
              :clearable="false"
              class="invoice-filter-select d-block"
            >
              <template slot="selected-option" slot-scope="option">
                {{ option.icerik["tr"].baslik }}
              </template>
              <template slot="option" slot-scope="option">
                {{ option.icerik["tr"].baslik }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Üye Gizlilik Metni">
            <v-select
              v-model="form.var_uye_gizlilik_k_no"
              :options="sayfalar"
              :reduce="(sayfa) => sayfa.k_no"
              label="baslik"
              :clearable="false"
              class="invoice-filter-select d-block"
            >
              <template slot="selected-option" slot-scope="option">
                {{ option.icerik["tr"].baslik }}
              </template>
              <template slot="option" slot-scope="option">
                {{ option.icerik["tr"].baslik }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Üye Kvkk Metni">
            <v-select
              v-model="form.var_uye_kvkk_k_no"
              :options="sayfalar"
              :reduce="(sayfa) => sayfa.k_no"
              label="baslik"
              :clearable="false"
              class="invoice-filter-select d-block"
            >
              <template slot="selected-option" slot-scope="option">
                {{ option.icerik["tr"].baslik }}
              </template>
              <template slot="option" slot-scope="option">
                {{ option.icerik["tr"].baslik }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Mesafeli Satış Sözleşme Metni">
            <v-select
              v-model="form.var_mesafeli_satis_sozlesme_k_no"
              :options="sayfalar"
              :reduce="(sayfa) => sayfa.k_no"
              label="baslik"
              :clearable="false"
              class="invoice-filter-select d-block"
            >
              <template slot="selected-option" slot-scope="option">
                {{ option.icerik["tr"].baslik }}
              </template>
              <template slot="option" slot-scope="option">
                {{ option.icerik["tr"].baslik }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Cayma Hakkı Metni">
            <v-select
              v-model="form.var_cayma_hakki_k_no"
              :options="sayfalar"
              :reduce="(sayfa) => sayfa.k_no"
              label="baslik"
              :clearable="false"
              class="invoice-filter-select d-block"
            >
              <template slot="selected-option" slot-scope="option">
                {{ option.icerik["tr"].baslik }}
              </template>
              <template slot="option" slot-scope="option">
                {{ option.icerik["tr"].baslik }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Varsayılan Ödeme Yönetemi">
            <v-select
              v-model="form.var_odeme_yontem"
              :options="odemeYontemleri"
              :reduce="(odeme) => odeme.value"
              label="baslik"
              :clearable="false"
              class="invoice-filter-select d-block"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Varsayılan Sipariş Durum">
            <v-select
              v-model="form.var_siparis_durum"
              :options="siparisDurumlar"
              :reduce="(siparis) => siparis.k_no"
              label="baslik"
              :clearable="false"
              class="invoice-filter-select d-block"
            >
              <template slot="selected-option" slot-scope="option">
                {{ option.icerik["tr"].baslik }}
              </template>
              <template slot="option" slot-scope="option">
                {{ option.icerik["tr"].baslik }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Sipariş İptal Durum">
            <v-select
              v-model="form.var_siparis_iptal_durum"
              :options="siparisDurumlar"
              :reduce="(siparis) => siparis.k_no"
              label="baslik"
              :clearable="false"
              class="invoice-filter-select d-block"
            >
              <template slot="selected-option" slot-scope="option">
                {{ option.icerik["tr"].baslik }}
              </template>
              <template slot="option" slot-scope="option">
                {{ option.icerik["tr"].baslik }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Sepet Ekleme Modu">
            <v-select
              v-model="form.var_sepet_ekleme_modu"
              :options="sepetEklemeModu"
              :reduce="(odeme) => odeme.value"
              label="baslik"
              :clearable="false"
              class="invoice-filter-select d-block"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label-cols="8" label="Üye Olmadan Yorum Yapma">
            <b-form-checkbox
              class="float-right"
              :checked="form.uye_olmadan_yorum_yapma"
              v-model="form.uye_olmadan_yorum_yapma"
              switch
              size="lg"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label-cols="8" label="Üye Olmadan Siparis Ver">
            <b-form-checkbox
              class="float-right"
              :checked="form.uye_olmadan_siparis_ver"
              v-model="form.uye_olmadan_siparis_ver"
              switch
              size="lg"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="pt-1">
        <b-col cols="12" md="6">
          <div class="d-flex">
            <b-button
              squared
              block
              type="submit"
              size="lg"
              variant="primary"
              class="mr-2"
            >
              <i class="fad fa-save pr-2"></i>
              {{ form.k_no == null ? "EKLE" : "GÜNCELLE" }}
            </b-button>
            <b-button squared size="lg" variant="danger">
              <i class="fad fa-trash"></i>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { defineComponent, ref } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import store from "@/store";

export default defineComponent({
  components: {
    vSelect,
  },
  setup(_, context) {
    const expo = {};
    const toast = useToast();

    expo.uyeGruplari = ref([]);
    expo.sayfalar = ref([]);
    expo.siparisDurumlar = ref([]);

    expo.odemeYontemleri = ref([
      {
        value: "kredi_karti",
        baslik: "Kredi Kartı",
      },
      {
        value: "havale_eft",
        baslik: "Havale Eft",
      },
      {
        value: "kapida_odeme",
        baslik: "Kapıda Ödeme",
      },
    ]);

    expo.sepetEklemeModu = ref([
      {
        value: "adet",
        baslik: "Adet Satım",
      },
      {
        value: "bolge_tarih",
        baslik: "Bölge Tarih",
      },
    ]);

    expo.form = ref({
      k_no: null,
      var_uye_k_no: null,
      var_uye_soz_k_no: null,
      var_uye_gizlilik_k_no: null,
      var_uye_kvkk_k_no: null,
      var_mesafeli_satis_sozlesme_k_no: null,
      var_cayma_hakki_k_no: null,
      var_odeme_yontem: null,
      var_siparis_durum: null,
      var_sepet_ekleme_modu: "adet",
      uye_olmadan_yorum_yapma: false,
      uye_olmadan_siparis_ver: false,
    });

    const handlerConfig = async () => {
      context.emit("show", true);

      await store.dispatch("uyeGrupListele");
      expo.uyeGruplari.value = store.getters.getUyeGrupListele;

      await store.dispatch("sayfaListele");
      expo.sayfalar.value = store.getters.getSayfalar;

      await store.dispatch("siparisDurumListele");
      expo.siparisDurumlar.value = store.getters.getSiparisDurumlari;

      await store.dispatch("digerAyarlarGetir").then((res) => {
        if (res.data.data !== null) {
          expo.form.value = res.data.data;
        }
      });
      context.emit("show", false);
    };

    handlerConfig();

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit("show", true);
      store
        .dispatch("digerAyarlarEkle", expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success(
              expo.form.value.k_no == null
                ? "Ekleme Başarılı."
                : "Güncelleme Başarılı",
              { position: "bottom-left" }
            );
            context.emit("show", false);
          }
        })
        .catch((err) => {
          context.emit("show", false);
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0
                ? Object.keys(err.data.dup)[0] + " " + err.data.message
                : err.data.message,
              { position: "bottom-left" }
            );
          }
        });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
